import React, { Component } from "react";


import * as constants from "../common/constants";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";


export class ProposalSubmissionHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            height: 11
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleRevise = this.handleRevise.bind(this);
    }

    handleChange = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    handleRevise = () => {
        //console.log("handle revise");
    }

    //strDivHeight = (number) => { setTimeout(function () {
    //    const name = "panel-" + number + "-header";
    //    const elem = document.getElementById(name);
    //    const top = elem.clientHeight / 2 - 14;
    //    //this.setState({ height: top });

    //    console.log('returned value = ', top);
    //    //return top;
    //    }, 1);
    //};

    render() {

        const proposal = this.props.proposal;

        var content = (
            <div className="secondary-accordion">
                <Accordion expanded={this.state.expanded} onChange={this.handleChange}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${proposal.proposalId}-content`}
                        id={`panel-${proposal.proposalId}-header`}
                    >
                        <div className="accordion-inner-header">
                            <div className="inner-title">{this.props.proposal.proposalName}</div>
                            <div className="inner-secondary">
                                <div className="submit-date past-date">
                                    <div className="submit-text">Submitted on <span className="submit-time">{this.props.proposal.proposalCreateDateString}<small>{this.props.proposal.proposalCreateTimeString}</small></span></div>
                                </div>
                                {this.props.isActive &&
                                    <div className="vertical-center">
                                        <div className="button-placeholder" style={{ width: 120, height: 30 }}>&nbsp;</div>
                                    </div>
                                }
                                <div className="inner-accordion-arrow accordion-trigger" type="button" data-toggle="collapse" data-target={`question-secondary-${proposal.proposalId}-accordion`}><img src="./images/icon-arrow-right-mdgray.svg" alt="arrow-down"/>
                                </div>
                            </div>
                        </div>


                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="collapse show expanded-inner">
                            {!this.props.isRd &&
                              <div className="row">
                                <div className="col-lg-4">
                                    <ul className="chevron-list">
                                        {
                                            this.props.proposal.files.filter((file, i) => i % 3 === 0).map((file) => (
                                                <li key={file.id } className="business-doc">
                                                    <div className="outer-link">
                                                        <div className="document-details">
                                                            <div className="document-name">Business Document</div>
                                                            <div className="document-file">{ file.fileName }</div>
                                                        </div>
                                                        <div className="chevron-right"><div className="arrow-right"></div></div>
                                                    </div>
                                                </li>
                                            ))  
                                         }
                                    </ul>
                                </div>

                                <div className="col-lg-4">
                                    <ul className="chevron-list">
                                        {
                                            this.props.proposal.files.filter((file, i) => i % 3 === 1).map((file) => (
                                                <li key={file.id} className="business-doc">
                                                    <div className="outer-link">
                                                        <div className="document-details">
                                                            <div className="document-name">Business Document</div>
                                                            <div className="document-file">{file.fileName}</div>
                                                        </div>
                                                        <div className="chevron-right"><div className="arrow-right"></div></div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                <div className="col-lg-4">
                                    <ul className="chevron-list">
                                        {
                                            this.props.proposal.files.filter((file, i) => i % 3 === 2).map((file) => (
                                                <li key={file.id} className="business-doc">
                                                    <div className="outer-link">
                                                        <div className="document-details">
                                                            <div className="document-name">Business Document</div>
                                                            <div className="document-file">{file.fileName}</div>
                                                        </div>
                                                        <div className="chevron-right"><div className="arrow-right"></div></div>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                    
                              </div>
                            }
                            {this.props.isRd && (
                                <div className="row">
                                    <div className="col-lg-4">
                                        <ul className="chevron-list">
                                            {
                                                this.props.proposal.files.filter((file, i) => file.type === constants.TechPdf).map((file) => (
                                                    <li key={file.id} className="technical-doc">
                                                        <div className="outer-link">
                                                            <div className="document-details">
                                                                <div className="document-name">Technical Document</div>
                                                                <div className="document-file">{file.fileName}</div>
                                                            </div>
                                                            <div className="chevron-right"><div className="arrow-right"></div></div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                            
                                        </ul>
                                    </div>
                                    <div className="col-lg-4">
                                        <ul className="chevron-list">
                                            {
                                                this.props.proposal.files.filter((file, i) => file.type === constants.BusinessPdf || file.type === constants.BusinessExcel ).map((file) => (
                                                    <li key={file.id} className="business-excel-doc">
                                                        <div className="outer-link">
                                                            <div className="document-details">
                                                                <div className="document-name">{file.type === constants.BusinessPdf ? "Business Document" : "Excel Document" }</div>
                                                                <div className="document-file">{file.fileName}</div>
                                                            </div>
                                                            <div className="chevron-right"><div className="arrow-right"></div></div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-lg-4">
                                        <ul className="chevron-list">
                                            {
                                                this.props.proposal.files.filter((file, i) => file.type === constants.HumanPdf).map((file) => (
                                                    <li key={file.id} className="misc-doc">
                                                        <div className="outer-link">
                                                            <div className="document-details">
                                                                <div className="document-name">PHS Human Subjects and Clinical Trials PDF</div>
                                                                <div className="document-file">{file.fileName}</div>
                                                            </div>
                                                            <div className="chevron-right"><div className="arrow-right"></div></div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            )}

                        </div>
                    </AccordionDetails>
                </Accordion>
                {this.props.isActive &&
                    <div className="control-posit visible" style={{ top: this.state.height }}>
                        <div className="button-container">
                            {this.props.isActive &&
                                <Link to={`/reviseProposal/${proposal.proposalId}`}>
                                    <button className="MuiButtonBase-root MuiButton-root med-btn-primary" aria-label={`button-to-${proposal.proposalId}`} tabIndex="0" onClick={this.handleRevise} type="button">
                                        <span className="MuiButton-label">
                                            Revise
                                        </span>
                                        <span className="MuiTouchRipple-root"></span>
                                    </button>
                                </Link>}
                        </div>
                        {/*<div className="inner-accordion-arrow accordion-trigger" type="button" data-toggle="collapse" data-target={`question-secondary-${proposal.proposalId}-accordion`}><img src="./images/icon-arrow-right-mdgray.svg" alt="arrow-down" /></div>*/}
                        </div>
                    }
            </div>
        );

        return (content);
    }
}

export default ProposalSubmissionHistory;