import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import DataColumn from "../common/dataColumn";
import DateTime from "../common/DateTimeConversionMethods";

import SolicitationType from "../../enums/SolicitationType";

export class SolicitationHeader extends Component {

    constructor(props) {
        super(props);
    }
    
    componentWillReceiveProps(newProps) {

    }
    render() {
        let headerInfo = this.props.headerInfo;
        const isBaseSol = (headerInfo?.solicitationTypeId === SolicitationType.Base);
        const noticeType = headerInfo?.noticeType;
        return (

            <div className="multi-level-header float-header">
                <div className="header-primary">
                    <div className="solicit-primary">
                        <div className="solicit-information">
                            <p>{noticeType} Information</p>
                            {headerInfo?.samUrl?.endsWith("view")
                                ? (<a href={`${headerInfo.samUrl}`} target="_blank">
                                    {headerInfo.solicitationNumber}<img src="images/exitlink.svg" alt="external-link" />
                                </a>)
                                : (<label>{headerInfo.solicitationNumber}</label>)
                            }
                            
                        </div>
                    </div>
                    <div className="solicit-title">{headerInfo.solicitationTitle}</div>
                </div>
                <div className="header-secondary">
                    <div className="detail-row">
                    <Link to={`/home`}>
                            <span><img src="./images/icon-arrow-left-blue.svg" alt="back-to-solicitations" />Back to {noticeType}</span>
                        </Link>
                        <div className="secondary-head-table">
                            <DataColumn
                                label="Closing Date"
                                date={headerInfo?.responseDeadlineDateStr}
                                time={headerInfo?.responseDeadlineTimeStr}
                                type="dateTime"
                            />
                            <DataColumn
                                label="Notice Type"
                                text={headerInfo?.noticeType}
                                type="plainText"
                            />
                            <DataColumn
                                label="Purchasing Agent"
                                text={headerInfo?.primaryCs}
                                type="plainText"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}