import React, { Component } from 'react';
import Footer from "./Footer";
import Paper from '@material-ui/core/Paper';

class HowToSubmit extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            <div className="section-heading">How To Submit an Electronic Quote</div>
                            <Paper className="content-container">
                            <ol>
                                    <li>Log in to the NIH eSASS (electronic Simplified Acquisition Submission System).</li>
                                    <li>Click <strong>Submit a Quote</strong> for the RFQ for which you wish to submit a quote from “Contract RFQs Available for Electronic Submission” page.</li>
                                    <li>Enter a name for the quote.</li>
                                    <li>If your solicitation requires to submit a Separation of Business(Price) and Technical Quotes, Browse and select the Technical Document, Business Document, and Excel Document(s).
                                    <ol type="a">
                                            <li>The Technical Quote, including all appendices, must be saved in a single PDF or Word file. The Technical document must enable word searches to the maximum extent practicable. Forms and/or documents requiring signature(s) may be scanned, but must be merged into the single Technical document.</li>
                                            <li>The Business Quote must be comprised of the following files:
                                                <ol type="I">
                                                    <li><strong>Required:</strong> A single PDF or Word document of your Business Quote, including all attachments and/or appendices as required by the RFQ. The Business Quote should be created in a PDF or Word format that enables word searches to the maximum extent practicable. Forms and/or documents requiring signature(s) may be scanned, but must be merged into the single Business Quote PDF or Word file. Do NOT password-protect or encrypt the document file.</li>
                                                    <li><strong>Optional:</strong> Cost breakdown(s) as required by the RFQ, in its original Excel format only. The cost breakdown(s) in excel must be identical to the one(s) in the Business Quote document.</li>
                                                </ol>
                                            </li>
                                    </ol>
                                </li>
                                
                                <li>Click <strong>Submit Quote.</strong></li>
                                <li>Once you upload and submit your quote, you can only revise your quote by overwriting previously uploaded documents. You can find submissions to revise or view under <strong>My Submission History</strong>. You have up until the closing date and time of the RFQ to make as many revisions as you need.</li>
                                <li>After the closing date and time, the system prevents further quote submissions or revisions. You may still view your submissions after the quote closing date and time under <strong>My Submission History</strong>.</li>
                                </ol>
                            </Paper>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
};

export default HowToSubmit;