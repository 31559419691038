import React, { Component } from "react";

import SolicitationType from "../../enums/SolicitationType";

export class SolicitationFilter extends Component {
    constructor(props) {
        super(props);
        this.state = { isToggleOn: null };

        // this.handleClickInvited = this.handleClick.bind(this);
    }

    handleClickInvited(isBaseSolicitation) {
        let isToggleOnResult = this.state.isToggleOn === isBaseSolicitation ? null : isBaseSolicitation;
        this.setState({ isToggleOn: isToggleOnResult });
        this.props.filterClick(isBaseSolicitation);
    }

  //handleClickInvited() {
   //   this.setState(state => ({
   //       isToggleOn: !state.isToggleOn
   //   }));
   // }

  render() {
    //let setFilter = (filterName) => {
    //    if (this.props.activeFilter === filterName) {
    //      if (filterName !== 'All') {
    //        this.props.filter('All', true);
    //        this.props.setActiveFilter('All');
    //      }
    //    } else {
    //        this.props.filter(filterName, true);
    //        this.props.setActiveFilter(filterName);
    //    }
    //};

    const isToggleOn = this.state.isToggleOn;
    var content = (
        <div className="solicitation-actions">
            {this.props.solList.length > 0 && <div className="solicitation-filter-btns">
                <button
                    onClick={() => this.handleClickInvited(false)}
                    className={`btn ${isToggleOn === false ? 'active' : ''}`}>
                    <span>{this.props.solList.length}</span>
                    <span>Invited RFQs</span>
                </button>
                <button
                    onClick={() => this.handleClickInvited(true)}
                    className={`btn ${isToggleOn === true ? 'active' : ''}`}>
                    <span>{this.props.solList.filter(key => key.solicitationTypeId === SolicitationType.Base).length}</span>
                    <span>Open RFQs</span>
                </button>
            </div>}
            {this.props.solList.length > 0 && 
            <span>Select to filter RFSs below</span>}
        </div>
    );

    return (
      /*this.props.solPageModel ?*/ content /*: ''*/
    );
  }
}

export default SolicitationFilter;