import React, { Component } from 'react';
import Footer from "./Footer";
import Paper from '@material-ui/core/Paper';

class About extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            <div className="section-heading">Electronic Simplified Acquisition Submission System</div>
                            <Paper className="content-container">
                                <React.Fragment>
                                    <p>NIH has implemented a new process for electronic submission of contract quotes.The electronic Simplified Acquisition Submission System (eSASS) is one component of NIH’s integrated, secure system for the electronic submission, capture, tracking and review of Solicitation documents. eSASS was developed for the purposes of streamlining our contract quote submission and review processes, preparing for the eventual elimination of paper submissions, and reducing costs associated with storage of large paper files.</p>

                                    <div className="sub-header">Features:</div>
                                    <ul>
                                        <li>Upload files for electronic submission against a list of active contract RFQ/NOI/Sources Sought</li>
                                        <li>Link to RFQ/NOI/Sources Sought in sam.gov</li>
                                        <li>View RFQ/NOI/Sources Sought titles and submission deadlines</li>
                                        <li>Track quote submission actions through "My Submission History"</li>
                                        <li>Revise a response, which will over-write previous versions</li>
                                    </ul>
                                    <div className="sub-header">Benefits to the research community:</div>
                                    <ul>
                                        <li>Easy-to-locate active RFQ/NOI/Sources Sought available for electronic submission</li>
                                        <li>Reduced costs for printing and mailing of paper files</li>
                                        <li>Eliminate security risk of paper processing and transportation time</li>
                                        <li>Real-time feedback on transmission success</li>
                                    </ul>
                                    <div className="sub-header">Benefits to the Office of Acquisitions:</div>
                                    <ul>
                                        <li>Single Source for all simplified acquisitions submission posted on SAM.gov</li>
                                        <li>Reduced costs for storing large paper files</li>
                                        <li>Enhanced security with electronic files</li>
                                        <li>Reduced processing and review time</li>
                                    </ul>
                                    <div className="sub-header">e-SASS Acquisitions:</div>
                                    <ul>
                                        <li>Please log in to  SAM.gov to view all RFQ/NOI/Sources Soughts  and follow the instructions on submission of documents.</li>
                                    </ul>
                                </React.Fragment>
                            </Paper>
                        </div>
                    </div >
                </div>
                <Footer />
            </div>
        )
    };
};

export default About;