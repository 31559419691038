import React, { Component } from "react";


export class SubmissionHistoryFilter extends Component {
  constructor(props) {
      return super();
  }

  render() {
    let setFilter = (filterName) => {
        if (this.props.activeFilter === filterName) {
          if (filterName !== 'All') {
            this.props.filter('All', true);
            this.props.setActiveFilter('All');
          }
        } else {
            this.props.filter(filterName, true);
            this.props.setActiveFilter(filterName);
        }
    };

    var content = (
        <div className="solicitation-actions">
            <div className="solicitation-filter-btns">
                <button className="btn" onClick={() => { setFilter('All') }}>
                    <span>{this.props.solicitationsSubmittedTo.solicitationsSubmitted}</span>
                    <span>RFQ/NOI/Sources Sought Submitted To</span>
                </button>
                {/*<button className={`btn ${this.props.activeFilter === 'All' ? 'active' : ''}`} onClick={() => { setFilter('All') }}>
                    <span>{this.props.solicitationsSubmittedTo.solicitationsSubmitted}</span>
                    <span>Solicitations Submitted To</span>
                </button>*/}
                <button className={`btn ${this.props.activeFilter === 'OpenSolicitations' ? 'active' : ''}`} onClick={() => { setFilter('OpenSolicitations') }}>
                    <span>{this.props.solicitationsSubmittedTo.openSolicitationCount}</span>
                    <span>Open RFQ/NOI/Sources Sought</span>
                </button>
                <button className={`btn ${this.props.activeFilter === 'Proposals' ? 'active' : ''}`} onClick={() => { setFilter('Proposals') }}>
                    <span>{this.props.solicitationsSubmittedTo.submittedProposals}</span>
                    <span>Submitted Capability statements/Quotes</span>
                </button>
            </div>
            <span>Select to filter RFQ/NOI/Sources Sought below</span>
        </div>
    );

    return (
      /*this.props.solPageModel ?*/ content /*: ''*/
    );
  }
}

export default SubmissionHistoryFilter;
