import React, { Component } from 'react';
import { toast } from "react-toastify";
import Footer from "../Footer";
// import '../siteStyles.css';

import moment from 'moment-timezone';
import submissionHistoryService from "../../services/submissionHistoryService";
import SubmissionHistoryFilter from './SubmissionHistoryFilter';
import SolicitationSubmissionHistory from './SolicitationSubmissionHistory';

class SubmissionHistory extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            solSubmissions: {},
            activeFilter: "All",
            currentFilterModel: null,
            loading: true,
            pageError: false,
            solicitationsFiltered: {}
        };

       
        this.setActiveFilter = this.setActiveFilter.bind(this);
        this.filter = this.filter.bind(this);
        this.filterSolicitationSubmissions = this.filterSolicitationSubmissions.bind(this);
        
    }
    filter = async (filter, isFilter) => {
        this.setState({ loading: true, filter: filter });
        this.filterSolicitationSubmissions(filter);
    }
    setActiveFilter = async (value) => {
        let filterModel = null;
        
        switch (value) {
            case 'OpenSolicitations':
                filterModel = {
                    'label': 'Open Solicitations',
                    'key': 'openSolicitationCount'
                };
                break;
            case 'Proposals':
                filterModel = {
                    'label': 'Proposals Received',
                    'key': 'proposalCount'
                };
                break;
            case 'All':
                filterModel = {
                    'label': 'Solicitations Submitted To',
                    'key': 'all'
                };
                break;
             default:
                break;
        
        }
        this.setState({ activeFilter: value, currentFilterModel: filterModel });
    }

    async filterSolicitationSubmissions(filter) {

        //console.log("Populate Solicitations filter", filter);
        let solSubmissions = null;
        let filteredSolicitations = null;
         const currentTime = moment().tz("America/New_York").format();
        //const currentTime = 'date';
        switch (filter) {
        case 'OpenSolicitations':
                solSubmissions = { ...this.state.solSubmissions };
                filteredSolicitations = solSubmissions.solicitations.filter(sol => sol.solicitationResponseDeadline > currentTime );
                solSubmissions.solicitations = filteredSolicitations;
                this.setState({ solicitationsFiltered: solSubmissions.solicitations });
            break;
        case 'Proposals':
                this.setState({ solicitationsFiltered: this.state.solSubmissions.solicitations  });
            break;
        case 'All':
                this.setState({ solicitationsFiltered: this.state.solSubmissions.solicitations });
                break;
            default:
                break;
        }

        this.setState({ loading: false});
    }

    async componentWillMount() {

        try {
            
            const { data: solSubmissions } = await submissionHistoryService.getSubmissionHistoryData();
            //console.log("received data", solSubmissions);


            setTimeout(() => {
                    this.setState({
                        solSubmissions,
                        solicitationsFiltered: solSubmissions.solicitations,
                        loading: false
                    });
                },
                1);

        } catch (ex) {
            //console.log(ex.response);
            this.setState({ pageError: true });
            toast.error("Error fetching submission data");
            //setTimeout(() => window.location = "/", 3000);
        }
    }

    render() { 

        if (this.state.pageError)
            return null;

        if (this.state.loading)
            return <div className="loader" style={{ position: "fixed", top: "40%", left: "45%" }}>Loading...</div>;

        return ( 
            <React.Fragment>
                <div className="content">
                    <div className="main-content">
                        <div className="solicitation-header-container solicitation-header-right">
                            <SubmissionHistoryFilter 
                                solicitationsSubmittedTo={this.state.solSubmissions}
                                filter={this.filter}
                                activeFilter={this.state.activeFilter} 
                                setActiveFilter={this.setActiveFilter}
                            />
                        </div>
                        <div className="section-heading">Submission History</div>

                        
                        <div className="white-accordions nested-accordions">
                        {this.state.solicitationsFiltered.map((sol) => (
                                <SolicitationSubmissionHistory key={sol.solicitationID} solicitation={sol} />
                                ))
                            }
                        </div>
                    </div>
                </div>
                <Footer />
        </React.Fragment> 
        );
    }
}
 
export default SubmissionHistory;