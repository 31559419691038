import React, { Component } from 'react';
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import _ from 'lodash';
//import auth from "../services/authService";
//import SolicitationFilter from './secure/SolicitationFilter';
import Sidebar from './common/sidebar';
import Pagination from '@material-ui/lab/Pagination';
import solicitationService from "../services/solicitationService";
import HTTPStatusCodes from "../enums/HTTPStatusCodes";
import RecordStatus from "../enums/RecordStatus";
import SolicitationType from "../enums/SolicitationType";

export class SolicitationList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            resultsPerPage: 20,
            frontEndPagination: true,
            selectedSolicitationId: null,
            selectedTaskAreaContractID: null,
            selectedSolicitationTitle: null,
            loading: false,
            vendorOrgName: null,
        }
    }
    



    solicitationTypeClass = (noticeTypeName) => {
        switch (noticeTypeName) {
            case 'Solicitation':
                return 'RFQ';
            case 'Combined Synopsis/Solicitation':
                return 'combined';
            case 'Sources Sought':
                return 'sources';
            case 'Presolicitation':
                return 'pre-notice';
            case 'Special Notice':
                return 'pre-notice';
            case 'Award':
                return 'award';
            default:
                return 'other';
        }
    };
    setPageNumber = (value) => {
        this.setState({ currentPage: value });
    }




    renderSamUrl(solicitation) {
        if (solicitation.samUrl == "" || solicitation.solicitationNumber == "")
            return "";
        return <a href={solicitation.samUrl} target="_blank" className="project-link">{solicitation.solicitationNumber}</a>;
    }

    render() {
        let totalPages = Math.ceil(this.props.solList.length / this.state.resultsPerPage);
        let startIndex = (this.state.currentPage - 1) * this.state.resultsPerPage;
        let endIndex = startIndex + this.state.resultsPerPage;
        let solsCurrentPage = this.state.frontEndPagination ? this.props.solList.slice(startIndex, endIndex) : this.props.solList;

        let updatePage = (event, newPageNumber) => {
            this.setPageNumber(newPageNumber);

            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        };
        return (

            <div className="container-fluid solicitation-landing external">

                {this.state.frontEndPagination && (<div className="pagination"><Pagination count={totalPages} page={this.state.currentPage} onChange={updatePage} aria-label="top pagination naviation" /></div>)}



                {solsCurrentPage.map(sol => {
                    const solicitationId = sol.solicitationID;
                    return <div className="project-banner-preview combined" className={`project-banner-preview ${this.solicitationTypeClass(sol.noticeTypeName)}`} key={solicitationId}>
                        <div className="project-banner-header">
                            <div className="project-type">{sol.noticeTypeName}</div>
                            <div className="solicitation-banner-right">
                                 <span className="project-banner-sol-number">{sol.samUrl.endsWith("view") ? (<a href={sol.samUrl} target="_blank" className="project-link">
                                        {sol.solicitationNumber}
                                 </a>) : sol.solicitationNumber}</span>
                            </div>
                        </div>
                        <div className="project-banner-title">{sol.solicitationTitle}</div>
                        <div className="project-banner-footer">
                            <div className="banner-details">
                                {this.props.isUserLoggedIn ?
                                    (<div className="solicitation-action-btns external">
                                        <Link to={`/submitProposal/${solicitationId}`} >
                                            <button className="btn">
                                                <span>Submit {sol.noticeTypeName != "RFQ" ? "Capability Statements/Quotes" : "Quotes"}</span>
                                            </button>
                                        </Link>

                                        {false && <div className="proposal-submitted">
                                            <span>2</span>
                                            <span>Submitted</span>
                                        </div>}
                                    </div>) :
                                    (<div className="button-container">
                                        <button className="MuiButtonBase-root MuiButton-root btn-white" tabIndex="0" type="button"
                                            onClick={() => { window.location = '/otp/renderOtp'; }}>
                                            <span className="MuiButton-label">
                                                Login to submit {sol.noticeTypeName != "RFQ" ? "capability statements/quotes" : "quotes"}</span>
                                            <span className="MuiTouchRipple-root"></span>
                                        </button>
                                    </div>)
                                }
                                <div className="MuiTableContainer-root">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th scope="col" className="sr-only">Action Buttons</th>
                                                <th scope="col">Purchasing Agent</th>
                                                <th scope="col">Agency</th>
                                                <th scope="col">Closing Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>

                                                </td>
                                                <td data-label="Purchasing Agent">{sol.primaryPoc}</td>
                                                <td data-label="Agency">{sol.solicitationTargetIcAgency}/{sol.solicitationTargetIC}</td>
                                                <td data-label="Closing Date">{sol.responseDeadlineDateString} <small>{sol.responseDeadlineTimeString}</small></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                })
                }

                {this.state.frontEndPagination && (<div className="pagination"><Pagination count={totalPages} page={this.state.currentPage} onChange={updatePage} aria-label="bottom pagination navigation" /></div>)}

            </div>
        );
    }
}

export default SolicitationList;