import React, { Component } from 'react';

export class BrowserCheckBanner extends Component {
    state = {
        showCheck: true
    }

    closeCheck = () => {
        this.setState({
            showCheck: false
        });
    }

    render() {
        const browserMessage = "Note: Lower versions of Internet Explorer are not supported by eSASS system. Please use Internet Explorer version 11 or higher, Google Chrome, Safari or Mozilla Firefox.";
        return (
            <div>
                {this.state.showCheck &&
                    <div className="browser-site-status">
                        <div>
                            <img src="/images/icon-exclamation-circle-orange.svg" alt="alert icon"/>
                            <div dangerouslySetInnerHTML={{ __html: browserMessage }} ></div>
                        </div>
                        <div>
                            <img src="/images/x-gray.svg" onClick={this.closeCheck} alt="close icon" />
                        </div>
                    </div >}
            </div>
        );
    }
};