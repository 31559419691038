import React, { Component } from 'react';
import Footer from "../Footer";

class DeadlineError extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content">
                        <div className="tile-section contact-section">
                            
                            <h4>Quote could not be received. Solicitation response deadline has passed.</h4>

                            <p style={{ marginTop: 30 }}>For questions regarding a specific Solicitation, contact the primary point of contact listed in the Solicitation.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    };
};

export default DeadlineError;