export const ShowSidebar = true;
export const ShowEditSolicitationSidebar = true;
export const MaxContentSizeAllowed = 406000000; //todo: actual value - 406000000 , testing value -406000
export const Error = "Error";
export const NotAllowed = "NotAllowed";
export const MaxContentSizeLimitExceeded = "MaxContentSizeLimitExceeded";
export const TechPdf = 1;
export const HumanPdf = 2;
export const BusinessPdf = 3;
export const BusinessExcel = 4;
export const NonRDBusinessDocs = 5;
export const supportEmail = "eSASSAdministrator@niaid.nih.gov";
export const AllUploadAcceptTypes = ".pdf, .docx, .doc, .xlsx, .xls";
export const AllUploadExtensions = [".pdf", ".docx", ".doc", ".xlsx", ".xls"];
export const AllUploadErrorMsg = "Only PDF, Word or Excel files are allowed";
export const TechDocAcceptTypes = ".pdf, .docx, .doc";
export const TechDocExtensions = [".pdf", ".docx", ".doc"];
export const TechDocErrorMsg = "Only PDF, Word files are allowed";
export const BusinessDocAcceptTypes = ".pdf, .docx, .doc";
export const BusinessDocExtensions = [".pdf", ".docx", ".doc"];
export const BusinessDocErrorMsg = "Only PDF, Word files are allowed";
export const BusinessExcelAcceptTypes = ".xlsx, .xls";
export const BusinessExcelExtensions = [".xlsx", ".xls"];
export const BusinessExcelErrorMsg = "Only Excel files are allowed";
export const HumanSubjectAcceptTypes = ".pdf";
export const HumanSubjectExtensions = [".pdf"];
export const HumanSubjectErrorMsg = "Only PDF file is allowed";
export const FileTypeNotAllowed = "NotAllowed";
