import React, { Component } from 'react';
import Footer from "../Footer";
import { supportEmail } from "../common/constants";

//const errorStyle = {
//    backgroundColor: "#e3e3e3",
//    margin: "20px",
//    padding: "20px",
//    borderRadius: "10px"
    
//}

class Error extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content">
                        <div className="tile-section contact-section">
                            <h2>Internal Server Error</h2>

                            <p>For questions regarding a specific RFQ, contact the primary point of contact listed in the RFQ.
                                For technical questions about this site, contact <a href={`mailto:${supportEmail}`} target="blank">eSASS Admin</a></p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    };
};

export default Error;
