import React, { Component } from 'react';
import Footer from "../Footer";
import { supportEmail } from "../common/constants";

const invalidAppUser = {
    backgroundColor: "#e3e3e3",
    margin: "20px",
    padding: "20px",
    borderRadius: "10px"
    
}

class AccountLocked extends Component {
    render() {
        return (
            <div>
                
                {/*<div className="primary-jumbo-text jumbotron-lg-content primary-jumbo-text jumbotron-text container jumbotron-notice">*/}
                {/*<h1>Sorry, you are not authorized to access this page. Please click <a href="/">here</a> to go back!</h1>*/}

                <div style={invalidAppUser}>

                <h2>Account Locked</h2>

                    Sorry, your eSASS account is locked or is no longer active. Please contact the <a href={`mailto:${supportEmail}`} target="_blank">eSASS Admin</a>

                <br />
                </div>
                <Footer />
            </div>

        )
    };
};

export default AccountLocked;
