import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "reactstrap";
import auth from "../../services/authService";

const styleObj = {
    fontSize: "10pt",
    color: "#a12",
    textAlign: "center",
    paddingTop: "0",
    position: "absolute",
    top: "0",
    left: "620px"
}

export class BannerHeaderSecure extends Component {
    
    constructor(props) {
        super(props);
        this.state = { user: null, icRoles: [], selectedRole: "", selectChanged: false};
    }

    icSelectHandler = function (trg) {
        const selectedIcRole = this.getIcRole(trg.value);
        localStorage.setItem("selectedIcRole", JSON.stringify(selectedIcRole));
        //this.props.icTrigger(selectedIcRole);
        window.location.reload();
    }

    getIcRole = (idx) => {
        const icRoles = this.state.icRoles;
        if (idx < icRoles.length) return icRoles[idx];
        else return null;
    }

    componentWillMount() {
        const user = auth.getCurrentUser();
        this.setState({ user: user });
        var icRoles = auth.getUserRoles();
        var defaultIcRole = this.props.defaultIcRole; 
        //console.log('%%%%%%%%%%%%%%%%%%% Mount IcRole');
        //console.log(defaultIcRole);
        this.setState({ icRoles: icRoles, selectedRole: defaultIcRole, defaultIcRole: defaultIcRole });
    }

    componentWillReceiveProps(nextProps) {
        var updatedIcRole = nextProps.defaultIcRole;
        //console.log('!!!!!!!!!!!!!!! updated props IcRole');
        //console.log(updatedIcRole);
        this.setState({ selectedRole: updatedIcRole, defaultIcRole: updatedIcRole });
    }

    render() {
        const userName = this.state.user ? this.state.user.firstName + " " + this.state.user.lastName : "";
        const selectedRole = this.state.selectedRole;
        const defaultIcRole = this.state.defaultIcRole;

        return (  
            <div>
                <div className="top-header">
                    <div className="nih-details">
                        <a href="#" className="logo-nih">
                            <img src="images/logo-nih-mark.svg" className="logo-nih-mark" alt="National Institutes of Health (NIH) Logo" />
                            <img src="images/logo-nih-niaid-keyline-white.svg" className="logo-nih-niaid-text" alt="National Institute of Allergy and Infectious Diseases" />
                        </a>
                        <div className="application-details">
                            <h1>electronic Simplified Acquisition Submission System</h1>
                        </div>
                    </div>
                    <div className="user-info">
                        <div className="user-vert-container">
                            <div className="user-name-title">
                                <div className="user-name">
                                    <strong>{userName}</strong>
                                </div>
                                <div className="user-title">
                                    External User
                                </div>
                            </div>
                            <div className="logout">
                                <NavLink tag={Link} className="btn-logout" to="/logout">Logout</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>              
        );
    }
}
