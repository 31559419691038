import https from "./httpService";

export async function getSiteMaintenanceSetting() {
    return https.get("api/application/SiteMaintenanceSetting");
}
export async function getSupportBannerSetting() {
    return https.get("api/application/SupportBannerSetting");
}
export default {
    getSiteMaintenanceSetting: getSiteMaintenanceSetting,
    getSupportBannerSetting: getSupportBannerSetting
};