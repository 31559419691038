import React, { Component } from "react";
//import auth from "../services/authService";

class LoginSection extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    redirectToOTP() {
        window.location = '/otp/renderOtp';
    }

    render() {
        return (
            <div className="jumbotron-lg-content">
                <div className="jumbotron-text">
                    <div className="primary-jumbo-text">Welcome to NIH's electronic Simplified Acquisition Submission System (eSASS)</div>
                    <div className="secondary-jumbo-text">The electronic Simplified Acquisition Submission System (eSASS) is a component of NIH's integrated, secure system for the electronic submission, capture, tracking and review of Solicitation documents.</div>
                </div>
                <div className="jumbotron-action">
                    <div className="guide-text">Ready to submit a capability statement/quote?</div>
                    <button className="MuiButtonBase-root MuiButton-root btn-guide" tabIndex="0" type="button" onClick={this.redirectToOTP}>
                        <span className="MuiButton-label">
                            eSASS Login
                        </span>
                        <span className="MuiTouchRipple-root"></span>
                        <div className="chevron-right"><img src="images/banner-arrow.svg" alt="site-login"/></div>
                    </button>
                    <a href="#" onClick={this.redirectToOTP} className="register-account">
                        Register new account
                        <img src="images/icon-arrow-right-blue-light.svg" alt="arrow create account" />
                    </a>
                </div>
            </div>
        );
    }
}

export default LoginSection;
