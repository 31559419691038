import React, { Component } from "react";

import { NavMenu } from "./NavMenu";
import { BannerHeader } from "./BannerHeader";
import { NavMenuAuthenticated } from "./secure/NavMenuAuthenticated";
import { BannerHeaderSecure } from "./secure/BannerHeaderSecure";
import { SiteMaintenanceBanner } from "./common/siteMaintenanceBanner";
import { BrowserCheckBanner } from "./common/browserCheckBanner";
import { SupportNoticeBanner } from "./common/supportNoticeBanner";

import auth from "../services/authService";
import applicationService from "../services/applicationService";

const isIE = (/MSIE (\d+\.\d+);/.test(navigator.userAgent) || navigator.userAgent.indexOf("Trident/") > -1);

export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);
        this.status = {};
        this.state = {
            siteMaintenance: {},
            supportBanner: {}
        };
    }

    async componentDidMount() {
        const { data: supportBanner } = await applicationService.getSupportBannerSetting();
        const { data: siteMaintenance } = await applicationService.getSiteMaintenanceSetting();
        this.setState({ siteMaintenance });
        this.setState({ supportBanner });
    }

    selectChangedH2 = (selectedIcRole) => {
        this.props.icRoleSelector(selectedIcRole);
    };

    renderSiteMaintenanceBanner() {
        const isActive = this.state.siteMaintenance.isActive;
        const maintenanceMessage = this.state.siteMaintenance.siteMaintenanceMessage;
        return isActive ? <SiteMaintenanceBanner maintenanceMessage={maintenanceMessage} /> : <React.Fragment />;
    }
    renderSupportBanner() {
        const isActive = this.state.supportBanner.isActive;
        const supportBannerMessage = this.state.supportBanner.supportBannerMessage;
        return isActive ? <SupportNoticeBanner supportNoticeBannerMessage={supportBannerMessage} /> : <React.Fragment />;
    }
    renderBannerHeader(user) {
        return user ? <BannerHeaderSecure icTrigger={this.selectChangedH2} defaultIcRole={this.props.defaultIcRole} /> : <BannerHeader />;
    }

    renderNavMenu(user) {
        return user ? <NavMenuAuthenticated username={this.props.user ? this.props.user.FirstName + " " + this.props.user.LastName + "[" + this.props.user.Email + "]" : ""} /> : <NavMenu />;
    }

    render() {
        const user = auth.getCurrentUser();
        return <div role="main">
            {this.renderSupportBanner()}
            {isIE && <BrowserCheckBanner /> }
            {this.renderSiteMaintenanceBanner()}
            <div className="jumbotron jumbotron-header jumbotron-lg" style={{ marginBottom: "0", padding: "6px 0 0 0" }}>
                {this.renderBannerHeader(user) }
                {this.renderNavMenu(user) }
                <div style={{ marginLeft: "0", marginRight: "0" }} >{this.props.children}</div>
            </div>
        </div>;
    }
}