import https from "./httpService";
import auth from "../services/authService";
import DateTime from "../components/common//DateTimeConversionMethods";

export async function getSolicitations(search, sortBy) {

    const user = await auth.getCurrentUser();

    //const closingDateStartUtc = new Date(search.closingDateStart).toJSON();
    //const closingDateEndUtc = new Date(search.closingDateEnd).toJSON();

    var dateStart = new Date();
    dateStart.setFullYear(dateStart.getFullYear() - 1);
    var closingDateStartStr = DateTime.getDateIso(dateStart); // default start value -1 year
    //console.log("default start year -1");

    if (search.closingDateStart) { // only if search start date found
        //console.log("found start search year");
        var parsed = DateTime.getDateIso(search.closingDateStart); // start year from search

        if (search.originalclosingDateStart && parsed == DateTime.getDateIso(search.originalclosingDateStart)) {
            closingDateStartStr = DateTime.getDateIso(dateStart); // start year -1 year
            //console.log("use start year -1");
        }
        else {
            closingDateStartStr = parsed; // user search year 
           // console.log("use start year from search");
        }
    }


    var dateEnd = new Date();
    dateEnd.setFullYear(dateEnd.getFullYear() + 1);
    var closingDateEndStr = DateTime.getDateIso(dateEnd); // default start value +1 year
    //console.log("default end year +1");

    if (search.closingDateEnd) { // only if search end date found
        //console.log("found end search year");
        var parsed = DateTime.getDateIso(search.closingDateEnd); // end year from search
        closingDateEndStr = parsed; // user search year 
        //console.log("use end year from search");
    }

       

    var searchQuery = "";
    //if (search) {
    //    searchQuery = "closingDateStart=" + search.closingDateStart + "&closingDateEnd=" + search.closingDateEnd
    //        + "&institute=" + search.institute + "&agency=" + search.agency + "&searchKeyword=" + search.keyword;
    //}

    if (search) {
        searchQuery = "closingDateStart=" + closingDateStartStr + "&closingDateEnd=" + closingDateEndStr
            + "&institute=" + search.institute + "&agency=" + search.agency + "&searchKeyword=" + search.keyword;
    }

    //var filterQuery = "&filter=" + filter;
    var sortByQuery = "&sortBy=" + sortBy;

    if (!user) {
        return https.get(
            "api/solicitations/getSolicitations?"/* + icIdQuery*/ + searchQuery/* + filterQuery*/ + sortByQuery
        );
    } else {
        return https.get(
            "api/solicitations/getSolicitationsSecure?"/* + icIdQuery*/ + searchQuery/* + filterQuery*/ + sortByQuery
        );
    }    
}

export async function getSolicitation(solId) {

    if (!solId) {
        return null;
    }

    return https.get(
        "api/solicitations/Solicitation/" + solId
    );
}

export async function getDropDownListForSolicitation(icId) {
    //console.log(" Getting drop down data for icid:", icId);
    if (!icId) {
        return null;
    }

    return https.get(
        "api/solicitations/GetSolicitationCreationDropdownList?icId=" + icId
    );
}

export async function getInstituteList() {
    return https.get(
        "api/solicitations/InstituteDdList"
    );
}

export async function saveSolicitation(icid,solicitation) {
    const body = { ...solicitation }
    body.ActiveIc = icid;

    //console.log("Solicitation data to Save: ", body);
    
    return https.post("api/solicitations/create", body);
}

export async function getProposalById(proposalId) {
    if (!proposalId)
        return null;

   return https.get(
        "api/proposals/GetProposalById/" + proposalId
    );
}

export async function editSolicitation(icid, solicitation) {
    const body = { ...solicitation }
    body.ActiveIc = icid;

    //console.log("Solicitation data to Save: ", body);

    return https.post("api/solicitations/edit", body);
}

export async function getSolicitationAttachments(solicitationId) {
    if (!solicitationId)
        return null;

    return https.get(`api/solicitations/GetSolicitationAttachments/${solicitationId}`);
}





export default {
    getSolicitations: getSolicitations,
    getDropDownListForSolicitation: getDropDownListForSolicitation,
    saveSolicitation: saveSolicitation,
    getSolicitation: getSolicitation,
    getProposalById: getProposalById,
    editSolicitation: editSolicitation,
    getInstituteList: getInstituteList,
    getSolicitationAttachments: getSolicitationAttachments,
};