import React, { Component } from 'react';
import Footer from "./Footer";
import { supportEmail } from "./common/constants";
import Paper from '@material-ui/core/Paper';

class ContactUs extends Component {
    render() {
        return (
            <div>
                <div className="content">
                    <div className="main-content centered-content">
                        <div className="container-fluid">
                            <div className="section-heading">
                                Contact Us
                            </div>
                            <Paper className="content-container">For questions regarding a specific RFQ, contact the primary point of contact listed in the RFQ. For technical questions about this site, contact <a className="link-textonly" href={`mailto:${supportEmail}`} target="blank">eSASS Admin</a></Paper>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default ContactUs;